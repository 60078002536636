import {provideHttpClient, withFetch} from '@angular/common/http';
import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {provideEffects} from '@ngrx/effects';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {environment} from 'environments/environment';
import {provideCacheableAnimationLoader, provideLottieOptions} from 'ngx-lottie';
import {routes} from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideZoneChangeDetection({eventCoalescing: true}),
        provideClientHydration(),
        provideAnimations(),
        provideStore(),
        provideHttpClient(withFetch()),
        provideStoreDevtools({
            name: 'Mercado-Meet',
            maxAge: 25,
            autoPause: true,
            trace: true,
            traceLimit: 75,
            logOnly: !environment.production,
            connectInZone: true
        }),
        provideEffects(),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !environment.production,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideCacheableAnimationLoader(),
    ],
};
